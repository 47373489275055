<template>
    <mapbox-map
        id="map"
        v-bind="$props"
        :access-token="accessToken"
        :map-style="mapStyle"
        @mb-created="onMapLoad"
    >
        <mapbox-popup v-if="isPopupOpen" :lng-lat="popupData.coordinates" @mb-close="onPopupClose">
            <div class="popup">
                <div class="popup__link" @click="$router.push(`/detail/${popupData.key}}`)">{{ popupData.tradeName }}</div>
            </div>
        </mapbox-popup>
    </mapbox-map>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxMap from '@studiometa/vue-mapbox-gl/dist/components/MapboxMap'
import MapboxPopup from '@studiometa/vue-mapbox-gl/dist/components/MapboxPopup'
import colors from '@/shared/styles/colors.scss'
import zipcodeMap from '@/shared/zipcodes'
import { isEmpty } from 'lodash'
import { api } from '../util/api'


export default {
    components: {
        MapboxMap,
        MapboxPopup,
    },
    props: {
        zoom: { type: Number, default: 11 },
        center: { type: Array, default: () => [-73.9677614, 40.7694034] },
        interactive: { type: Boolean, default: true },
    },
    data () {
        return {
            isMapLoaded: false,
            popupData: null,
        }
    },
    computed: {
        isPopupOpen () {
            return !isEmpty(this.popupData)
        },
        zipcode () {
            return this.$route.query.zipcode
        },
    },
    watch: {
        zipcode (zipcode) {
            this.flyToZipcode(zipcode)
        },
    },
    beforeCreate () {
        this.accessToken = 'pk.eyJ1IjoibWF0ZXZ6c3RpbmplayIsImEiOiJja25xNTV4OHYxcGxmMnVwcmxoamVtM2JvIn0.9afemYjTHe5xP4L-34zTBQ'
        this.mapStyle = 'mapbox://styles/mapbox/outdoors-v11'
    },
    methods: {
        flyToZipcode (zipcode) {
            if (zipcode) {
                this.map.flyTo({ center: zipcodeMap[zipcode], zoom: 15 })
            }
        },
        async onMapLoad (map) {
            const self = this
            this.isMapLoaded = true
            this.map = map

            const pins = await api.get('pinsJobs')
            await new Promise(resolve => this.map.on('load', resolve))
            this.flyToZipcode(this.zipcode)

            const dataset = pins.map(pin => pin.feature)
            addPins('dataset', dataset, colors.blue)

            function addPins (key, data, color) {
                map.addSource(key, {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        features: data,
                    },
                })
                map.addLayer({
                    'id': key,
                    'type': 'circle',
                    'source': key,
                    'paint': {
                        'circle-radius': 4,
                        'circle-color': color,
                    },
                })
                if (self.interactive) {
                    map.on('click', key, (e) => {
                        self.popupData = JSON.parse(e.features[0].properties.description)
                    })
                    map.on('mouseenter', key, () => {
                        map.getCanvas().style.cursor = 'pointer'
                    })
                    map.on('mouseleave', key, () => {
                        map.getCanvas().style.cursor = ''
                    })
                }
            }
        },
        onPopupClose () {
            this.popupData = null
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/colors';

#map {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    top: 0;
    box-sizing: border-box;
}
.popup {
    background-color: white;
    padding: 4px;
    font-size: 14px;
    border-radius: 8px;

    &__link {
        cursor: pointer;
        text-decoration: underline;
        color: $blue;
    }
}
</style>
