<template>
    <v-card class="company-listing">
        <div class="d-flex flex-row w-100 align-items-center px-10 py-6">
            <div class="flex-fill">
                <div class="company-listing__name">{{ tradeName }}</div>
                <div class="company-listing__address">{{ address }}</div>
                <div class="company-listing__address">{{ zipcode }}, {{ city }}, {{ state }}</div>
                <div class="d-flex flex-row pt-2">
                    <div class="pe-5"><a class="link-red-underline" @click="onSeeMore">See more</a></div>
                    <div><a href="https://docs.google.com/forms/d/e/1FAIpQLSfmTqtftmD6iEG8o4Ab3Grb5iOn8iH-0br-VNOYTDV6KqwPaw/viewform?usp=sf_link" target="_blank" class="link-black-underline">Tell us your wage theft story</a></div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row w-100 align-items-center px-10">
            <div class="w-100 border-bottom border-black aux-height-1px">&nbsp;</div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        slug: { type: String, required: true },
        tradeName: { type: String, required: true },
        address: { type: String, required: true },
        zipcode: { type: String, required: true },
        city: { type: String, required: true },
        state: { type: String, required: true },
    },
    methods: {
        onSeeMore () {
            this.$router.push(`detail/${this.slug}`)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/common';

.aux-height-1px{
  height:1px;
}
a.link-red-underline{
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: .8rem;
  color: #D91F2A;
}
a:hover.link-red-underline{
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: .8rem;
  color: #D91F2A;
}
a.link-black-underline{
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: .8rem;
  color: #000;
}
a:hover.link-black-underline{
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: .8rem;
  color: #000;
}

a.black-red-normal{
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: .8rem;
  color: #000;
}
a:hover.black-red-normal{
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: .8rem;
  color: #000;
}

.company-listing {
    margin: 0px 0;
    padding: 0px;
    min-height: 96px;
    align-items: center;

    &__name {
        font-family: 'Poppins', sans-serif;
        @include emphasized-font;
        font-size: 1.1rem;
        line-height: 1.1rem;
        padding-bottom: .5rem;
    }
    &__address {
        font-size: 1rem;
        line-height:1rem;
    }
}
</style>
