<template>
    <v-card class="company-listing">
        <div class="d-flex flex-row">
            <div class=""><v-icon>mdi-domain</v-icon></div>
            <div class="">
                <div class="company-listing__name">{{ tradeName }}</div>
                <div class="company-listing__address">{{ address }}</div>
                <div class="company-listing__address">{{ zipcode }}, {{ city }}, {{ state }}</div></div>
            <div class=""><v-btn class="green" small dark @click="onSeeMore">See more</v-btn></div>
            <div class=""><v-btn color="blue" small disabled>Add a complaint</v-btn></div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        slug: { type: String, required: true },
        tradeName: { type: String, required: true },
        address: { type: String, required: true },
        zipcode: { type: [String, Number], required: true },
        city: { type: String, required: true },
        state: { type: String, required: true },
    },
    methods: {
        onSeeMore () {
            this.$router.push(`detail/${this.slug}`)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/common';

.company-listing {
    margin: 32px 0;
    padding: 32px;
    min-height: 96px;
    display: flex;
    align-items: center;

    &__name {
        @include emphasized-font;
        font-size: 24px;
    }
    &__address {
        font-size: 18px;
    }
}
</style>
