import { render, staticRenderFns } from "./PermitsSearch.vue?vue&type=template&id=32925c2b&scoped=true&"
import script from "./PermitsSearch.vue?vue&type=script&lang=js&"
export * from "./PermitsSearch.vue?vue&type=script&lang=js&"
import style0 from "./PermitsSearch.vue?vue&type=style&index=0&id=32925c2b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32925c2b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VPagination})
