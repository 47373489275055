<template>
    <div class="search__container">
        <div class="search__results">
            <search-filter class="search__filter" />
            <div v-if="showingResultsFor" class="search__showing-query">We are showing results for <strong>{{ showingResultsFor }}</strong></div>
            <div class="search__showing-count">
                Showing
                <strong>{{ (page + 1) * perPage - perPage + 1 }}-{{ Math.min((page + 1) * perPage, totalCases) }}</strong>
                of
                <strong>{{ totalCases }}</strong>
                companies
            </div>
            <div v-if="false" class="search__stats">
                <div v-if="showingResultsFor" class="search__stats-label">{{ showingResultsFor }}: Total Results</div>
                <div class="search__stats-container">
                    <div class="search__stat">
                        <div class="search__stat-number">4</div>
                        <div class="search__stat-desc">Number of complaints</div>
                    </div>
                    <div class="search__stat">
                        <div class="search__stat-number">$30.000</div>
                        <div class="search__stat-desc">Wages Stolen</div>
                    </div>
                    <div class="search__stat">
                        <div class="search__stat-number">5</div>
                        <div class="search__stat-desc">People Affected</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="search__listings">
                    <company-listing
                        v-for="_case in cases"
                        :key="_case.key"
                        :slug="_case.key"
                        :trade-name="_case.ownerBusinessName"
                        :zipcode="_case.zipcode"
                        :city="_case.borough"
                        state="New York"
                        :address="_case.address"
                    />
                </div>
                <v-pagination class="pagination" :value="page + 1" :length="totalPages" circle @input="onPageClick" />
            </div>
        </div>
        <div class="search__map">
            <div class="search__map-container" :style="style">
                <map-element />
                <div v-if="false" class="legend">
                    <div class="legend__entry">
                        <div class="legend__mark legend__mark--blue" />
                        <div class="legend__label">Federal</div>
                    </div>
                    <div class="legend__entry">
                        <div class="legend__mark legend__mark--green" />
                        <div class="legend__label">NYC</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MapElement from '@/components/PermitsMap.vue'
import SearchFilter from '@/components/SearchFilter.vue'
import CompanyListing from '@/components/PermitsCompanyListing.vue'

export default {
    name: 'PermitsSearch',
    components: {
        SearchFilter,
        CompanyListing,
        MapElement,
    },
    computed: {
        ...mapGetters([
            'cases',
            'totalPages',
            'page',
            'totalCases',
            'perPage',
        ]),
        showingResultsFor () {
            const query = this.$route.query
            return query.name || query.naicsCodeDescription || query.zipcode
        },
        style () {
            const barHeight = this.$vuetify.application.top
            return {
                paddingTop: `${barHeight}px`,
                marginTop: `${-barHeight}px`,
            }
        },
    },
    watch: {
        $route () {
            this.buildQueryAndSearch()
        },
    },
    created () {
        if (this.$route.query.page) {
            this.buildQueryAndSearch()
        } else {
            this.onPageClick(1)
        }
    },
    methods: {
        ...mapActions([
            'searchPermits',
        ]),
        buildQueryAndSearch () {
            this.searchPermits(this.$route.query)

            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        onPageClick (page) {
            const query = { ...this.$route.query, page: page - 1 }
            this.$router.push({ path: 'search', query }).catch(console.warn)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/common';

.search {
    &__container {
        height: 100%;
    }
    &__showing-query {
        color: $coal;
        font-size: 20px;
        margin: 14px 0;
    }
    &__showing-count {
        color: $coal;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    &__stats {
        padding: 22px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 3px;
    }
    &__stats-label {
        color: $charcoal;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 25px;
    }
    &__stats-container {
        display: flex;
        justify-content: space-between;
    }
    &__stat {
        flex: 1;
    }
    &__stat:not(:first-child) {
        border-left: 1px solid rgba(0, 0, 0, 0.14);
    }
    &__stat-number {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        width: 100%;
    }
    &__stat-desc {
        color: $blue;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
    }
    &__filter {
        padding: 35px;
    }
    &__results {
        height: 100%;
        width: 740px;
        padding: 16px;
        position: relative;
    }
    &__map {
        position: absolute;
        display: block;
        top: 0;
        width: calc(100% - 740px);
        right: 0;
        height: 100%;
    }
    &__map-container {
        position: sticky;
        z-index: 1;
        height: 100vh;
        width: 100%;
        margin: 0;
        top: 0;
        box-sizing: border-box;
    }
}
.legend {
    position: absolute;
    z-index: 1;
    top: 90px;
    right: 50px;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    width: fit-content;

    &__entry {
        display: flex;
        align-items: center;
        margin: 10px;
    }
    &__mark {
        margin-right: 10px;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        &--green {
            background-color: $green;
        }
        &--blue {
            background-color: $blue;
        }
    }
    &__label {
        width: fit-content;
    }
}
.pagination {
    margin: 16px;
}
</style>
